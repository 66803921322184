import { Link } from "react-router-dom";


function YouWon() {
  return (
    <div className="container">
      <h1>You Won!</h1>
      <img src="/assets/youwon.gif" style={{ height: "40%" }}></img>
      <Link to="/" className="no-underline">
        <button
          className="big-btn play-again"
        >
          Play Again
        </button>
      </Link>

    </div>
  );
}

export default YouWon;
