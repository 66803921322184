import { Link } from "react-router-dom";

function OpponentWon() {
  return (
    <div className="container">
      <h1>Your opponent won {":("}</h1>
      <img src="/assets/youlost.gif" style={{ height: "40%" }}></img>
      <Link to="/" className="no-underline">
        <button
          className="big-btn play-again"
        >
          Play Again
        </button>
      </Link>
    </div>
  );
}

export default OpponentWon;
