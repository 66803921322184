import JoinRoomScreen from "./JoinRoomScreen";

function VersusMode() {
  return (
    <div>
      <JoinRoomScreen />
    </div>
  );
}

export default VersusMode;
